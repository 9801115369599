<template>
    <div>
        <!-- <ReportManagement_Component></ReportManagement_Component> -->
        <router-view></router-view>
    </div>
</template>

<script>
import ReportManagement_Component from '../../components/PersonalCenter/ReportManagement_Component.vue';
export default {
    name: "ReportManagement",
    components: { ReportManagement_Component }
}
</script>

<style lang="scss" scoped>

</style>